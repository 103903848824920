.text-output {
    margin-bottom: 0;

    h2 {
        margin-bottom: rem-calc(22);
    }

    p {
        padding-top: rem-calc(24);
        line-height: rem-calc(28);
        font-size: rem-calc(18);
        letter-spacing: .02em;

        @include breakpoint(large) {
            max-width: 80%;
        }
    }
}