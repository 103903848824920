$bg-gray: rgb(247, 247, 247) !default;       // #f7f7f7
$black: rgb(41,41,41) !default;             //#292929

.home-page {
    section {
        padding: rem-calc(25) 0;

        @include breakpoint(medium) {
            padding: rem-calc(10) 0;
        }

        &.col-reverse {

            .grid-x {

                @include breakpoint(large) {
                    flex-direction: row-reverse;
                }

                .intro-img {
                    justify-content: center;

                    img {

                        @include breakpoint(large) {
                            right: initial;
                            left: 100px;
                        }
                    }
                }
            }
        }

        &.section-gray {
            background-color: $white;

            @include breakpoint(large) {
                background-color: $bg-gray;
            }

            .intro-text {
                padding: 0;

                @include breakpoint(medium) {
                    padding: 0 0 rem-calc(40);
                }

                @include breakpoint(large) {
                    padding: rem-calc(140) 0;
                }
            }
        }

        .intro-img {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            img {
                max-width: 90%;

                @include breakpoint(large) {
                    position: absolute;
                    right: 100px;
                    top: 120px;
                    max-width: initial;
                }
            }
        }

        .intro-text {
            margin: 0 auto;
            padding: 0;
            max-width: rem-calc(450);
            font-size: rem-calc(18);
            font-family: 'Open Sans';

            &.lead-text {
                text-align: center;

                @include breakpoint(large) {
                    max-width: rem-calc(700);
                }

                button {
                    margin: 40px auto 25px;
                }
            }

            @include breakpoint(medium) {
                padding: 0 0 rem-calc(60);
            }

            @include breakpoint(large) {
                padding: rem-calc(165) 0;
            }

            small {
                a {
                    margin-left: rem-calc(8);
                }
            }

            ul {
                margin: rem-calc(30) 0;
                padding: 0;
                line-height: rem-calc(28);
                font-size: rem-calc(18);
                list-style: none;

                @include breakpoint(medium) {
                    margin: rem-calc(10) 0 rem-calc(15);
                    font-size: rem-calc(15);
                }

                li {
                    position: relative;
                    margin-bottom: rem-calc(15);
                    padding-left: rem-calc(25);

                    &::before {
                        content: '\ed6f';
                        position: absolute;
                        top: 0;
                        left: 0;
                        font-family: 'Icomoon';
                    }

                    @include breakpoint(medium) {
                        margin-bottom: 0;
                        padding-left: rem-calc(20);
                    }
                }
            }

            p {
                margin-bottom: rem-calc(25);

                @include breakpoint(medium) {
                    margin-bottom: rem-calc(40);
                }
            }

            .button {
                display: block;
                margin-bottom: rem-calc(25);
                width: 100%;
                min-height: rem-calc(70);
                font-size: rem-calc(24);
                
                @include breakpoint(medium down) {
                    margin-top: rem-calc(40);
                    width: initial;
                    height: initial;
                    font-size: rem-calc(18);
                }
            }

            .link-proj {
                padding-left: rem-calc(25);
                color: $sbv-green;

                &:hover {
                    color: $sbv-dark-green;
                }

                &::after {
                    display: none;
                }

                &::before {
                    content: '\eec9';
                    position: absolute;
                    top: -2px;
                    left: 0;
                    font-size: rem-calc(20);
                    font-family: 'Icomoon';
                }
            }

            &.vomhof {
                li {
                    &::before {
                        color: $black;
                    }
                }
            }
            &.brunch {
                li {
                    &::before {
                        color: $black;
                    }
                }
            }
            &.schub {
                li {
                    &::before {
                        color: $black;
                    }
                }
            }
            &.stallvisite {
                li {
                    &::before {
                        color: $black;
                    }
                }
            }
        }
    }
}
