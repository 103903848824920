$farm-link-color: #000 !default;
$farm-link-color-hover: #aaa !default;
$info-box-link-color: #fff !default;
$info-box-text-color: #fff !default;
$social-media-link-color: #fff !default;

$sbv-light-brunch: rgba(110, 68, 71, .7) !default;
$info-box-border-brunch: rgb(149, 103, 106) !default;
$info-box-border-stallvisite: rgb(53, 145, 255) !default;
$info-box-border-toh: rgb(191, 255, 0) !default;

.comp-preview-container {
    $top-bar-height-plain: 68 !default;
    $top-bar-height-calc: rem-calc($top-bar-height-plain) !default;
    $top-bar-background-color: #fff !default;

    p {
        font-size: 14px;
        margin-bottom: 1rem;
        line-height: 1.6;
        text-rendering: optimizeLegibility;
    }

    .top-bar {
        &.open {
            .menu {
                li {
                    line-height: 68px !important;
                }
            }
        }
    }

    .comp-farm {
        padding-top: 90px;

        @include breakpoint(medium down) {
            padding-top: 178px;
        }

        @media (max-width: 292px) {
            padding-top: 260px;
        }


        .farm-address {
            margin-top: rem-calc(158); // 90 + 68

            @include breakpoint(medium down) {
                margin-top: rem-calc(246); // 178 + 68
            }

            @media (max-width: 292px) {
                margin-top: 260px;
            }
        }

        .comp-image-list-carousel + .detail-section {
            margin-top: $top-bar-height-calc;

            @include breakpoint(medium down) {
                margin-top: $top-bar-height-calc;
            }
        }

        .comp-image-list-gallery + .detail-section {
            margin-top: $top-bar-height-calc;

            @include breakpoint(medium down) {
                margin-top: $top-bar-height-calc;
            }
        }
    }

    .comp-farm a {
        position: static;

        &::after {
            content: none;
            color: $farm-link-color-hover;
        }

        &.social-link {
            color: $social-media-link-color;
        }
    }

    .comp-image-list-gallery {
        padding-top: 90px;

        @include breakpoint(medium down) {
            padding-top: 178px;
        }
    }

    .comp-image-list-carousel {
        padding-top: 178px;
    }

    .comp-navigation-detail {
        .top-bar-left {
            display: none;
        }

        .top-bar-right {
            .active-element {
                margin: 0;
                width: 100%;
            }
        }

        .top-bar.open {
            .menu {
                li {
                    padding-left: 0;
                }
            }
        }
    }

    .top-bar {
        position: sticky;
        top: 90px;
        left: 0;
        width: 100%;
        height: $top-bar-height-calc;
        background-color: $top-bar-background-color;
        transition: $nav-transition;
        border-bottom: 1px solid $nav-border-color;
        z-index: 1000;
        box-shadow: 1px 15px 30px -15px rgba(0, 0, 0, .16);

        @include breakpoint(medium down) {
            top: 178px;
        }

        @media (max-width: 292px) {
            top: 260px;
        }

        .main-wrapper {
            padding: 0;
        }

        .navbar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            float: right;
        }

        %dots {
            content: '';
            display: block;
            position: absolute;
            top: 10px;
            left: 0;
            width: 34px;
            height: 5px;
            background-color: $white;
            border-radius: 15px;
        }
    }

    .farm-brunch-info-box,
    .farm-stallvisite-info-box {
        display: none;
    }
}

.comp-preview-container-global {
    font-size: 14px;

    body {
        padding-top: 0;
    }
}

.panel {
    background-color: $brunch-panel-background-color;
    border-left: rem-calc(7) solid black;
    margin: rem-calc(15) 0 rem-calc(35) 0;

    @include breakpoint(medium) {
        max-width: rem-calc(555);
    }

    .panel-content {
        display: flex;
        font-size: rem-calc(18);
        padding: rem-calc(15);

        .panel-info {
            margin-right: rem-calc(13);
        }
    }
}