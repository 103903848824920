.comp-project-status-widget {
    margin-bottom: rem-calc(20);

    @include breakpoint(medium) {
        max-width: 555px;
        margin-left: 0;
    }

    .status-widget-container {
        border-radius: 0 6px 6px 0;
        border: 1px solid #e6e6e6;

        @include breakpoint(small down) {
            border-radius: 0;
        }

        .left-content {
            width: 120px;
            height: 120px;
            padding: 0;

            @include breakpoint(medium down) {
                display: none;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .central-content {
            color: #fff;
            display: flex;
            width: auto;
            flex-grow: 1;
            align-content: space-between;
            padding: 15px;
            font-weight: bold;
            flex-wrap: wrap;
            position: relative;

            @include breakpoint(medium) {
                min-width: calc(100% - 150px);
                max-width: calc(100% - 182px);
                width: 70%;
            }


            p {
                padding-left: 5px;
            }

            .radio {
                margin: 0;
                position: absolute;
                left: 15px;
                bottom: 15px;

                & > label {

                    &::before {
                        margin-right: .5rem;
                        width: 1.15rem;
                        height: 1.15rem;
                    }
                }
            }
        }

        @include breakpoint(small down) {
            .central-content {
                height: 80px;

                .radio {
                    left: 18px;
                    bottom: 8px;
                }
            }
        }

        .right-content {
            background-color: #AEAEAE;
            border-radius: inherit;
            height: 120px;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgba(255, 255, 255, .5);
            font-weight: bold;
            text-align: center;

            @include breakpoint(medium up) {
                max-width: 182px;
                min-width: 150px;
            }

            @include breakpoint(small down) {
                height: 80px;
            }

            &.active {
                a {
                    background: $sbv-green;
                    color: $white;
                    display: flex;
                    height: 100%;
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                    border-radius: inherit;
                    transition: all .25s ease-out;

                    &::after {
                        display: none;
                    }

                    &:hover {
                        background: $sbv-dark-green;
                        transition: all .25s ease-out;
                    }
                }
            }

            & > * {
                margin: 0;
            }
        }

        &.online {
            .right-content {
                background-color: #7CB64F;
            }
        }
    }
}

// should be removed when project-view's for vomhof & brunch are made
.comp-vomhof {
    .comp-project-status-widget {
        .central-content {
            background: $sbv-vomhof;
        }
    }
}

.comp-brunch {
    .comp-project-status-widget {
        .central-content {
            background: $sbv-brunch;
        }
    }
}
