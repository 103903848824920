.comp-widget-farm-overview {

    .progress-bar-container {
        margin: auto;
        width: 75%;

        @include breakpoint(large) {
            width: 47%;
        }

        .progress-bar-text {
            font-size: rem-calc(24);
            margin-bottom: rem-calc(15);
        }

        .progress-bar {
            height: 5px;
            background: #818181;
            margin-bottom: 3rem;

            span {
                height: 5px;
                display: block;
                background: $sbv-green;
            }
        }

        .button_edit-farm {
            display: block;
            margin: auto;
        }
    }
}