$request-delete-border: #D9D9D9 !default;

.comp-request-delete-button {

    .deregister-project-text {
        padding-top: rem-calc(25);
        border-top: 1px solid $request-delete-border;
        margin-top: rem-calc(50.5);
    }

    .request-delete-project-button {
        margin-bottom: 1rem;

        @include breakpoint(medium) {
            max-width: 555px;
            margin-left: 0;
        }

        @include breakpoint(medium down) {
            margin-bottom: 3rem;
        }
    }

    a {
        color: rgba(41, 41, 41, 0.25)
    }

    a:after {
        display: none !important;
    }

    .clearfix:after {
        content: "";
        display: table;
        clear: both;
    }
}
