$black: rgb(41,41,41) !default;  //#292929

.comp-project-overview-initial {

    &.vomhof {
        .intro-text-bullet-list {
            li {
                &::before {
                    color: $black;
                }
            }

        }

        .button {
            @include button-style($black, $black, $white);

            &[disabled],
            &[disabled]:focus,
            &[disabled]:hover {
                @include button-style($disabled-background, $disabled-background, $disabled-color);
            }
        }
    }
}

.comp-project-overview-published {
    &.vomhof {
        .upper-content {
            .button {
                @include button-style($sbv-green, $sbv-dark-green, $white);
            }
        }

        .bottom-content {
            background: $sbv-vomhof;
        }
    }
}