$sbv-green: rgb(124,182,79) !default;        // #7cb64f
$heading-font-family: 'Asap', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$sbv-black: #0a0a0a;

.step-container {
  position: relative;
  margin: 0 auto;
  line-height: 40px;
  width: 100%;
  font-weight: bold;
  font-family: $heading-font-family;
  display: inline-flex;
  font-size: 12px;

  .inline-first {
    position: absolute;
    left: 5%;
  }

  .inline-second {
    position: absolute;
    left: 60%;
  }

  @include breakpoint(medium) {
    .inline-first {
      top: 50%;
      left: 37%;
      transform: translate(-50%, -50%);
    }

    .inline-second {
      top: 50%;
      left: 65%;
      transform: translate(-50%, -50%);
    }
  }

  @include breakpoint(large) {
    .inline-first {
      left: 42%;
    }

    .inline-second {
      left: 60%;
    }
  }

  .inline-first, .inline-second {
    display: inline-block;

    .step-title {
      padding-left: rem-calc(20);
    }

    .circle {
      height: 40px;
      width: 40px;
      background-color: $sbv-green;
      color: #fff;
      border-radius: 50%;
      text-align: center;
      display: block;
      margin: 0 auto;
      float: left;
      font-size: 17px;

      &.checked {
        &::before {
          content: '\ed6f';
          position: absolute;
          top: 0;
          left: rem-calc(13px);
          font-family: 'Icomoon';
          font-size: rem-calc(14px);
        }
      }

      &.hollow {
        background-color: #fff;
        color: $sbv-black;
        border: 1px solid $sbv-black;
      }
    }
  }
}

@include breakpoint(medium) {
  .step-container {
    font-size: 16px;
  }

  .comp-farm-register-view .file-upload.no-init {
    width: 99% !important;
  }
}