$sbv-green: rgb(124,182,79) !default;        // #7cb64f

.comp-registration {
    .page-header {
        text-align: center;
        max-width: rem-calc(716);
        margin-top: rem-calc(80);
        margin-left: auto;
        margin-right: auto;
        overflow:auto;

        h1 {
            label {
                .steps {
                    color: $sbv-green;
                    display: inline-block;
                }
            }
        }
    }

    .page-body {
        margin-top: rem-calc(60);
    }

    .side-menu {
        padding-top: rem-calc(79);
    }
}

.comp-farm-register-view {
    //width: 100%;

    .fill-out-later-button {
        margin-top: rem-calc(-120);
    }
}

@media screen and (min-width: 640px) {
    .comp-farm-register-view {
        width: 85%;
    }
}

@media screen and (max-width: 640px) {
    .comp-farm-register-view {
        width: 105%;
    }
}