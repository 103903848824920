$black: rgb(41,41,41) !default;  //#292929

.comp-widget-twint {
    margin-top: rem-calc(20);
    padding-bottom: rem-calc(10);
    padding-top: rem-calc(10);
    position: relative;
    background: rgba(171, 170, 170, 0.17);
    margin-bottom: rem-calc(20);

    .button {
        &.twint-black {
            background-color: $black;

            &:hover {
                background-color: $black;
            }
        }
    }

    .twint-image {
        height: rem-calc(80);
    }

    &.active {
        padding-bottom: 0;

        /* Medium and up */
        @media screen and (min-width: 64em) {
            padding-bottom: rem-calc(10);
        }

        .twint-image {
            margin-top: rem-calc(10);
            margin-right: rem-calc(20);
            height: rem-calc(80);
            display: block;

            /* Medium and up */
            @media screen and (min-width: 64em) {
                display: inline;
                margin: rem-calc(10);
            }
        }
    }

    .title {
        /* Medium and up */
        @media screen and (min-width: 64em) {
            padding-left: rem-calc(20);
            margin-top: rem-calc(5);
        }
    }

    .twint-black {
        /* Medium and up */
        @media screen and (min-width: 64em) {
            margin-left: rem-calc(20);
            margin-top: rem-calc(8);
            margin-bottom: 0;
        }
    }

    .close {
        position: absolute;
        right: rem-calc(16);
        top: rem-calc(16);
        width: rem-calc(16);
        height: rem-calc(16);
        opacity: 0.7;

        &:hover {
            opacity: 0.9;
        }

        &:before, &:after {
            position: absolute;
            left: rem-calc(7);
            content: ' ';
            height: rem-calc(16);
            width: rem-calc(2);
            background-color: $black;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }
}

