$sub-menu-small-navigation-height: rem-calc(68) !default;
$sub-menu-navigation-height: rem-calc(90) !default;
$brunch-panel-background-color: #F7F7F8 !default;

.comp-brunch {
    margin-top: $sub-menu-small-navigation-height;

    @include breakpoint(large) {
        margin-top: $sub-menu-navigation-height;
    }

    .single-project-header {
        a {
            color: $sbv-brunch;
        }

        .sp-desc {
            .sp-list {

                li {

                    &::before {
                        color: $sbv-brunch;

                    }
                }
            }
        }
    }

    .page-body {
        margin-top: rem-calc(60);
        margin-bottom: rem-calc(20);
    }

    .button-create-farm {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 200px;
    }

    .accordion {
        &.sec-optional {
            .acc-toggle {
                background-color: $sbv-green;
            }

            .acc-title {
                &:hover {
                    .acc-toggle {
                        background-color: $sbv-dark-green;
                    }
                }
            }
        }
    }

    .form-field {
        .optional-form-link {
            h3 {
                &::before {
                    background-color: $sbv-brunch;
                }

                @include breakpoint(large) {
                    max-width: 85%;
                }
            }
        }
    }

    .comp-project-status-widget {
        .central-content {
            background-color: $sbv-brunch;
        }

    }
}