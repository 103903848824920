.ul-impressum {
  list-style: none;
  margin: 0;

  li {
    margin: rem-calc(48) 0;

    p {
      margin-bottom: rem-calc(4);
    }
  }
}