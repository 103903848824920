.comp-my-farm {
    .page-body {
        margin-top: rem-calc(60);
        margin-bottom: rem-calc(20);
    }

    .side-menu {
        padding-top: rem-calc(79);
    }

    .button-create-farm {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 200px;
    }

    ​.map-info {
        text-align: center;
        margin-bottom: rem-calc(15);
    }

    .map-info > div {
        vertical-align: top;
        display: inline-block;
    }

    .map-title {
        font-size: rem-calc(18);
    }

    .map-info > .opt {
        position: absolute;
        right: 0;
    }
}