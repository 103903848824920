@import "util/util";

// Colours
$white: rgb(255, 255, 255);             // #ffffff
$black: rgb(0, 0, 0);                   // #000000
$text-color: rgb(41, 41, 41);           // #292929
$silver: rgb(196, 196, 196);            // #c4c4c4
$dove-gray: rgb(112, 112, 112);         // #707070
$modal-bg: rgba(33, 33, 33, .7);        // #212121
$red-error: rgb(204, 75, 55);           // #cc4b37

$disabled-background: rgba(174, 174, 174, 1);
$disabled-color: $white;
$disable-opacity: 0;

// Main Colours
$sbv-green: rgb(124, 182, 79);                  // #7cb64f
$sbv-dark-green: rgb(76, 134, 32);              // #4C8620
$sbv-vomhof: rgb(92, 144, 221);                 // #5c90dd
$sbv-dark-vomhof: rgb(29, 99, 171);             // #1d63ab
$sbv-brunch: rgb(110, 68, 71);                  // #754246
$sbv-dark-brunch: rgb(71, 25, 31);              // #47191f
$sbv-schub: rgb(93, 126, 45);                   // #52801a
$sbv-dark-schub: rgb(35, 83, 0);                // #235300
$sbv-stallvisite: rgb(1, 121, 188);             // #0179bc
$sbv-dark-stallvisite: rgb(0, 78, 139);         // #004e8b
$sbv-dark-lockpfosten: rgb(100, 98, 64);        // #646240
$sbv-yellow: rgb(255, 184, 25);                 // #ffb819
$sbv-yellow-dark: rgb(199, 136, 0);             // #c78800

$nav-border-color: rgba(124, 182, 79, .2784); // #86a65f - 27.84%
$top-header-color: rgb(51, 51, 51);

// fonts
$heading-font-family: 'Asap', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$body-font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$icons-font-family: 'Icomoon';
$footer-font-family: 'Asap', 'Helvetica Neue', Helvetica, Arial, sans-serif;

// input
$input-label-color: $black;
$input-focus-color: $sbv-green;
$input-bg-color: $white;
$input-border-color: $silver;
$input-font: $body-font-family;
$input-color: $black;
$input-placeholder-color: $silver;

// link colors
$main-navigation-top-header-link-hover-color: $sbv-green;

// select
$select-focus-color: $sbv-green;

// sizes
$main-wrapper-width: rem-calc(1280);
$main-navigation-height: rem-calc(108);
$main-navigation-height-full: rem-calc(165);
$main-navigation-top-header-height: rem-calc(56);
$sub-menu-small-navigation-height: rem-calc(68);
$sub-menu-navigation-height: rem-calc(90);

$button-palette: (
    primary: $sbv-green,
    secondary: $sbv-yellow
);

$image-list-border: rgb(204, 204, 204);
$button-close-color: $black;
$button-hover-opacity: .75;
