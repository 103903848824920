.login-wrapper {
    display: flex;
    justify-content: space-between;
    border: 1px solid $dove-gray;
    min-height: 100%;

    a {
        &:hover {
            opacity: 0.5;
        }
    }

    .login-close-button {
        font-size: rem-calc(30);
        float: right;
        position: absolute;
        cursor: pointer;
        top: rem-calc(25);
        left: rem-calc(30);
    }

    @media (max-width: 1023px) {
        .login-close-button {
            left: unset;
            right: rem-calc(30);
            top: rem-calc(45);
        }
    }

    .login-content {
        padding: rem-calc(30);
        font-size: rem-calc(18);
        width: 100%;

        @include breakpoint(large) {
            padding: rem-calc(40);
            width: 61.666666%;
        }

        @include breakpoint(large) {
            padding: rem-calc(140) rem-calc(85) 0;

            @media (max-height: 900px) {
                padding: 10vh rem-calc(85) 0;
            }
        }

        h1 {
            margin-bottom: rem-calc(30);
        }

        form {

            @include breakpoint(large) {
                text-align: right;
            }

            .label {
                display: block;
                color: $black;
                font-family: $heading-font-family;
                font-weight: 700;
                text-align: left;

                @include breakpoint(large) {
                    margin: rem-calc(30) 0 rem-calc(1);
                }
            }

            .input-field {
                margin-bottom: rem-calc(6);
            }

            input {
                margin: rem-calc(10) 0 rem-calc(6);
                padding: rem-calc(11) rem-calc(20);
                width: 100%;
                line-height: rem-calc(25);
                color: $black;
                font-size: rem-calc(15);
                border: rem-calc(1) solid $silver;
                box-shadow: none;

                &::placeholder {
                    color: $silver;
                }
            }

            .button {
                display: block;
                width: 100%;
                font-size: rem-calc(24);

                @include breakpoint(large) {
                    width: auto;
                    font-size: rem-calc(18);
                }
            }

            > p {
                margin: 20px 0;
            }

            > a {
                margin-right: rem-calc(20);
            }

            &.saving {
                display: none;
            }
        }
    }

    .login-bg {
        display: none;
        width: 38.333333%;

        @include breakpoint(large) {
            display: block;
        }
    }

    .loader-container {
        height: auto;
        position: relative;
        top: calc(25% - 20px);
    }
}
