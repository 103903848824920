@import "util/util";

$nav-border-color: rgba(124, 182, 79, .2784) !default; // #86a65f - 27.84%
$nav-link-color: rgb(41, 41, 41) !default; // #292929
$nav-link-hover-color: rgb(174, 174, 174) !default; // #aeaeae
$hamburger-menu: rgb(51, 51, 51) !default; // #333333
$nav-transition: left .2s;
$nav-sm-width: 78.26%;
$main-navigation-height-full: rem-calc(165) !default;
$main-navigation-height: rem-calc(108) !default;

body {
    position: relative;
    left: 0;
    transition: $nav-transition;

    &.nav-open {
        left: - $nav-sm-width;
        overflow: hidden;

        .top-bar {
            left: - $nav-sm-width;
        }

        nav {
            visibility: visible;
            left: (100 - $nav-sm-width);
        }

        @include breakpoint(large) {
            left: 0;
            overflow: initial;

            .top-bar {
                left: 0;
            }
        }
    }
}

.comp-navigation {
    @include flex;
    @include flex-direction(column);

    &.top-bar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: $main-navigation-height;
        background-color: $white;
        transition: $nav-transition;
        border-bottom: 1px solid $nav-border-color;
        z-index: 1000;

        @include breakpoint('large') {
            height: $main-navigation-height-full;
        }

        .main-wrapper {
            padding: 0;
        }
    }
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;

    .logo {
        max-width: rem-calc(159);
        max-height: 100%;
        overflow: hidden;

        @include breakpoint('small') {
            max-width: rem-calc(135);
            height: auto;
        }

        &::after {
            display: none;
        }

        img {
            height: 100%;
            max-height: 100px;

            @include breakpoint('small') {
                max-width: rem-calc(135);
                height: auto;
            }
        }
    }

    %hamburger {
        content: '';
        display: block;
        position: absolute;
        top: 10px;
        left: 0;
        width: 34px;
        height: 5px;
        background-color: $hamburger-menu;
    }

    .hamburger-menu {
        position: relative;
        margin-right: rem-calc(30);
        width: 34px;
        height: 25px;

        @include breakpoint(large) {
            display: none;
        }

        &::after {
            display: none;
        }

        span {
            @extend %hamburger;
            position: absolute;

            &::before {
                @extend %hamburger;
                top: -10px;
            }

            &::after {
                @extend %hamburger;
            }
        }
    }

    nav {
        visibility: hidden;
        display: flex;
        position: fixed;
        flex-direction: column;
        top: 0;
        right: 0;
        bottom: 0;
        left: 100%;
        background-color: $white;
        transition: $nav-transition;
        box-shadow: inset 20px 0 22px -22px rgba(0, 0, 0, .65);

        @include breakpoint(large) {
            visibility: visible;
            position: inherit;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            flex-grow: 1;
            height: 100%;
            box-shadow: none;
        }

        .main-nav {
            display: flex;
            flex-direction: column;
            margin: rem-calc(60) 0 0 20%;
            padding: 0;
            list-style: none;

            @include breakpoint(large) {
                flex-direction: row;
                align-items: center;
                height: 100%;
            }

            @include breakpoint(large) {
                margin: 0;
            }

            @include breakpoint(xlarge) {
                margin: 0;
            }

            li {
                margin: rem-calc(20) 0;

                @include breakpoint(large) {
                    margin: 0 0 0 rem-calc(40);
                }

                @include breakpoint(xlarge) {
                    margin: 0 0 0 rem-calc(60);
                }

                &.register {
                    padding: 0;
                }
            }

            a {
                font-family: $heading-font-family;
                font-weight: 700;
                font-size: rem-calc(18);
                color: $nav-link-color;
                transition: color .2s;
                letter-spacing: .02rem;
                white-space: nowrap;

                &:hover {
                    color: $nav-link-hover-color;
                }

                &::after {
                    display: none;
                }
            }

            .active {
                color: $nav-link-hover-color;
            }
        }
    }

    .register {
        @include breakpoint(medium) {
            align-items: center;
            padding: 0 rem-calc(20);
        }

        @include breakpoint(large) {
            width: rem-calc(266);
        }

        @include breakpoint(large up) {
            display: flex;
            justify-content: flex-end;
            padding: 0 0 rem-calc(20);
            height: 100%;
            border-left: 1px solid $nav-border-color;
        }

        button {
            min-width: 0;
            width: 83%;
            max-width: rem-calc(265);
            font-size: rem-calc(18);

            @include breakpoint(large up) {
                min-width: rem-calc(180);
                height: initial;
            }
        }
    }

    .logedin {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin: rem-calc(20) 0 rem-calc(20) 25%;

        @include breakpoint(large) {
            margin: 0;
            padding-left: rem-calc(40);
            justify-content: center;
            align-items: center;
            height: 100%;
            border-left: 1px solid $nav-border-color;
        }

        @include breakpoint(xlarge) {
            padding-left: rem-calc(70);
        }


        a {
            position: relative;
            color: $nav-link-color;
            line-height: rem-calc(35);
            font-size: rem-calc(18);
            font-family: $heading-font-family;
            white-space: nowrap;

            &::before {
                display: none;
                background-color: transparent;
                background: none;
            }

            &::after {
                display: none;
                background-color: transparent;
                background: none;
            }

            &:hover {
                color: $nav-link-hover-color;
            }

            @include breakpoint(medium) {
                line-height: rem-calc(28);
            }

            @include breakpoint(large) {
                color: $nav-link-color;
            }
        }
    }
}