$input-label-color: rgb(0, 0, 0) !default;
$input-bg-color: rgb(255, 255, 255) !default;
$input-border-color: rgb(196, 196, 196) !default;
$input-color: rgb(41, 41, 41) !default;
$input-mutted-color: rgb(153, 153, 153) !default;
$input-error-color: rgb(255, 0, 0) !default;
$input-focus-color: rgb(0, 0, 0) !default;
$input-icon-primary-color: rgba(255, 255, 255, 1) !default;
$input-icon-primary-bg-color: rgba(124, 182, 79, 1) !default;
$input-icon-primary-bg-hover-color: rgba(76, 134, 32, 1) !default;
$input-icon-small-top: rem-calc(19);
$red-error: rgb(204, 75, 55) !default;

.form-field {
    display: block;
    margin-bottom: rem-calc(48);

    input:not([type='checkbox']) {
        margin-bottom: rem-calc(2);
        width: 100%;
        appearance: none;
        padding: rem-calc(10) rem-calc(20);
        line-height: rem-calc(25);
        color: $input-color;
        font-size: rem-calc(18);
        font-family: $heading-font-family;
        font-weight: normal;
        background-color: $input-bg-color;
        border: 1px solid $input-border-color;
        border-radius: 0;
        box-shadow: none;
    }

    input[type='text'],
    input[type='password'],
    input[type='number'] {
        line-height: rem-calc(28);
        font-size: rem-calc(18);
        padding: rem-calc(15) rem-calc(20);
        letter-spacing: 0.02em;
        word-spacing: 0.02em;
    }

    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input:focus {
        outline-color: $input-focus-color;
    }

    &.disabled {
        opacity: .5;

        input {
            background-color: transparent;
            box-shadow: none;
            cursor: default;
        }
    }

    &.error {
        margin-bottom: rem-calc(6);

        input {
            border: 1px solid $red-error;
        }

        input + span {
            display: inline-block;
        }
    }

    &.not-valid {

        input {
            border: 1px solid $red-error;
        }
    }

    .optional-form-link {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: rem-calc(12) 0 rem-calc(24);
        cursor: pointer;

        h3 {
            margin: 0;
            display: inline-block;
            line-height: rem-calc(28);
            position: relative;

            &::before {
                content: '\ed5b';
                display: inline-block;
                font-size: rem-calc(15);
                width: rem-calc(28);
                height: rem-calc(28);
                line-height: 1.9;
                color: $input-icon-primary-color ;
                font-family: 'Icomoon';
                font-weight: 400;
                background-color: $sbv-green !important;
                border-radius: 100%;
                margin-right: rem-calc(10);
                padding-left: .41rem;
            }
        }

        &.label__icon-hidden {
            h3 {
                &::before {
                    content: none;
                }
            }
        }

        .opt-flag {
            position: absolute;
            right: 0;
            top: 0;
            pointer-events: none;

            @include breakpoint(medium down) {
                font-size: rem-calc(14);
            }
        }
    }

    .optional-form-field {
        position: relative;
        display: block;
        opacity: 1;

        &:before {

        }
    }

    .optional-form-field.hidden {
        display: none;
    }

    .label-container {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .icon {
            width: rem-calc(28);
            height: rem-calc(28);
        }

        .icon-help {
            position: absolute;
            top: 0;
            right: 0;
            color: $sbv-green;
            text-align: center;
            background: transparent;
            border-radius: 50%;
            cursor: pointer;
            z-index: 2;

            &:before {
                content: "\ed56";
                font-size: rem-calc(28);
                font-family: $icons-font-family;
            }

            &.primary {
                color: $sbv-green;
            }

            &.secondary {
                color: $sbv-yellow;
            }
        }

        .info-text {
            visibility: visible;
            position: absolute;
            top: 10px;
            right: 0;
            padding: rem-calc(20);
            width: rem-calc(315);
            min-height: rem-calc(50);
            color: $input-icon-primary-color;
            font-size: rem-calc(15);
            background: $input-icon-primary-bg-color;
            opacity: 1;
            transition: .3s ease;
            z-index: 1;

            @include breakpoint(medium down) {
                top: -5px;
            }

            @include breakpoint(small down) {
                width: initial;
            }
        }

        .info-text.hidden {
            visibility: hidden;
            opacity: 0;
            transition: .3s ease;
        }

        .label {
            display: inline-block;
            margin-bottom: rem-calc(12);
            color: $input-label-color;
            line-height: rem-calc(28);
            font-size: rem-calc(18);
            font-family: $heading-font-family;
            font-weight: 700;
            letter-spacing: .02em;
            word-spacing: .02em;
        }

        .label-description {
            margin: 0 0 rem-calc(12);
        }

        .iconOffset {
            margin-right: rem-calc(36);
        }
    }

    .optional-form-link.label-container {
        justify-content: normal;
    }

    .error-message {
        display: none;
        color: $red-error;
        font-size: rem-calc(11);
    }

    .close-optional {
        display: block;
        margin-top: rem-calc(30);
        text-align: right;
        pointer-events: none;

        a {
            color: $input-mutted-color;
            pointer-events: auto;

            &:after {
                background: $input-mutted-color;
            }
        }
    }
}