$checkbox-border: rgb(196, 196, 196) !default;      // #c4c4c4
$checkbox-checked: rgb(119, 179, 72) !default;      // #77b348
$input-error-color: red !default;
$soldout-default: rgb(174, 174, 174) !default;      // #aeaeae
$soldout-color: rgb(255, 255, 255) !default;        // #ffffff
$red-error: rgb(204, 75, 55) !default;

.checkbox {
    position: relative;
    display: block;
    margin-bottom: rem-calc(10);

    .no-box {
        vertical-align: middle;
        font-weight: bold;
        margin-right: 20px;

        &:before {
            content: none;
        }
    }

    > input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    > label {
        display: inline-flex;
        align-items: center;
        margin-bottom: rem-calc(12);
        color: $input-label-color;
        line-height: rem-calc(28);
        font-size: rem-calc(18);
        font-family: $heading-font-family;
        font-weight: 400;
        letter-spacing: .02em;
        word-spacing: .02em;
        cursor: pointer;

        &::before {
            content: '\ed6f';
            display: inline-flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            align-self: flex-start;
            margin-right: rem-calc(13);
            padding: 1px 0 0 2px;
            width: rem-calc(30);
            height: rem-calc(30);
            color: transparent;
            font-size: rem-calc(18);
            font-family: $icons-font-family;
            background-color: $white;
            border: 1px solid $silver;
            transition: all .2s ease-in-out;
        }
    }

    > input:checked + label {

        &::before {
            color: $sbv-green;

        }
    }

    &.error {
        margin-bottom: rem-calc(6);

        > input {

            &::before {
                border: 1px solid $red-error;
            }
        }
    }
    
    .error-message {
        display: block;
        flex: 1 0 100%;
        color: $red-error;
        font-size: .6875rem;
    }

    &.disabled {
        opacity: .5;

        > label {
            cursor: default;
        }
    }

    // Book switch checkbox

    &.fully-book-switch {

        input[type="checkbox"] {
            
            & + label {
                justify-content: space-between;
                margin-bottom: rem-calc(55);
                padding: 0 14px 0 6px;
                width: rem-calc(158);
                height: rem-calc(45);
                color: rgba($soldout-color, .5);
                font-weight: bold;
                background: $soldout-default;
                transition: padding .2s ease-in-out;
                border-radius: 23px;
                overflow: hidden;
                user-select: none;
                
                &::before {
                    content: '';
                    margin: 5px 0 0 0;
                    padding: 0;
                    width: rem-calc(35);
                    height: rem-calc(35);
                    border-radius: 50%;
                }
            }
    
            &[value="1"] + label,
            &[value="true"] + label,
            &:checked + label {
                flex-flow: row-reverse nowrap;
                padding: 0 6px 0 14px;
                color: $soldout-color;
                background: $sbv-brunch;
            }
        }
    }

    // Book switch when project is published

    .comp-project-overview-published &.fully-book-switch {
        margin: 0;

        input[type="checkbox"] {

            & + label {
                margin: 0;
            }

            &[value="1"] + label,
            &[value="true"] + label,
            &:checked + label {
                flex-flow: row-reverse nowrap;
                padding: 0 6px 0 14px;
                color: $soldout-color;
                background: $sbv-green;
            }
        }
    }

    &.fully-book-switch input[type="checkbox"]:checked + label {
        background: $sbv-green;
    }

    .info-text-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
    }

    .icon {
        width: rem-calc(28);
        height: rem-calc(28);
        z-index: 1;

        &.icon-help {
            position: absolute;
            top: 1px;
            right: 0;
            text-align: center;
            border-radius: 50%;
            cursor: pointer;
            
            &::before {
                content: "\ed56";
                font-size: rem-calc(28);
                font-family: $icons-font-family;
                font-weight: 400;
            }

            &::after {
                background: white;
                width: rem-calc(24);
                height: rem-calc(24);
                position: absolute;
                top: 2px;
                left: 2px;
                border-radius: 50%;
                overflow: hidden;
                z-index: -1;
            }

            &.primary { color: $sbv-green; }
        }
    }

    .info-text {
        visibility: visible;
        position: absolute;
        top: 11px;
        right: 0;
        padding: 1.25rem;
        width: 19.6875rem;
        min-height: 3.125rem;
        color: #fff;
        line-height: 1.25rem;
        font-size: .9375rem;
        background: #7cb64f;
        opacity: 1;
        transition: all .3s ease;

        &.hidden {
            visibility: hidden;
            opacity: 0;
            transition: .3s ease;
        }
    }
}
