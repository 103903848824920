$sbv-btn: rgb(124, 182, 79);            // #7Cb64F
$sbv-btn-hover: rgb(76, 134, 32);       // #4c8620
$switch-font-color: white !default;
$red-error: rgb(204, 75, 55) !default;

.btn-group-switch {
    display: flex;
    flex-wrap: wrap;

    label {
        width: 100%;
        margin-bottom: rem-calc(12);
        color: $input-label-color;
        line-height: rem-calc(28);
        font-size: rem-calc(18);
        font-family: $heading-font-family;
        font-weight: 700;
        letter-spacing: .02em; 
        word-spacing: .02em;
    }

    .button-switch {
        position: relative;
        margin: rem-calc(16) rem-calc(10) rem-calc(33) 0;
        min-width: rem-calc(167);
        height: rem-calc(60);
        background-color: transparent;
        overflow: hidden;
        font-size: rem-calc(18);
        font-weight: 700;
        color: $switch-font-color;
        border-radius: 7px;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: $sbv-btn;
            transition: background-color .2s;
            z-index: -1;
        }

        &:hover {

            &::before {
                background-color: $sbv-btn-hover;
            }
        }

        &.switch-selected {
            pointer-events: none;

            &::before {
                opacity: .5;
                background-color: $sbv-btn;
            }

            &::after {
                content: '\ed6f';
                position: absolute;
                top: rem-calc(16);
                left: rem-calc(16);
                display: block;
                width: rem-calc(29);
                height: rem-calc(29);
                line-height: 1.9;
                font-size: rem-calc(15);
                font-family: 'Icomoon';
                background: $sbv-btn;
                border-radius: 100%;
            }
        }
    }

    &.error {
        margin-bottom: rem-calc(6);

        .button-switch {
            border: 1px solid $red-error;
            margin: rem-calc(16) rem-calc(10) rem-calc(16) 0;
        }

        span {
            display: block;
            margin-bottom: rem-calc(16);
        }
    }

    .error-message {
        display: none;
        flex-basis: 100%;
        color: $red-error;
        font-size: rem-calc(11);
    }
}