.comp-not-found {
    .lead-heading {
        margin: rem-calc(100) auto rem-calc(50);
        max-width: 90%;
        text-align: center;
        letter-spacing: .01em;

        @include breakpoint(medium down) {
            margin-top: rem-calc(50);
            max-width: 70%;
        }
    }

    p,
    .link-wrapper {
        margin: 0 auto;
        max-width: 50%;
        text-align: center;

        @include breakpoint(medium down) {
            max-width: 70%;
        }

        @include breakpoint(small down) {
            max-width: 100%;
        }
    }

    .link-wrapper {
        margin-top: rem-calc(25);
    }

    & + footer {
        position: absolute;
        bottom: 0;
        width: 100%;

        .main-wrapper {
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
            padding: 0 rem-calc(40);
        }
    }
}