@import 'util/util';

$dark-gray: rgb(88, 88, 88) !default; // #585858
$platinum: rgb(227, 227, 227) !default; // #e3e3e3
$footer-text-color: rgb(51, 51, 51) !default; // #333333
$schweiz-red: rgb(209, 45, 38) !default; // #e30613
$footer-font-family: 'Asap', 'Helvetica Neue', Helvetica, Arial, sans-serif !default;

footer {
    margin-top: rem-calc(60);
    padding: rem-calc(61) 0 0 rem-calc(26);
    background-color: $platinum;

    @include breakpoint(large) {
        position: absolute;
        bottom: 0;
        width: 100%;
        margin-top: 0;
        padding: rem-calc(61) 0 rem-calc(71) 0;
    }

    .main-wrapper {
        display: flex;
        flex-direction: column;
        padding: 0;
        row-gap: rem-calc(14);

        @include breakpoint(large) {
            justify-content: space-between;
            align-items: center;
            padding: 0 rem-calc(40);
            row-gap: rem-calc(86);
        }
    }

    .social-media {

        @include flex;
        width: 100%;
        column-gap: rem-calc(100);

        @include breakpoint(medium down) {
            @include flex-direction(column);
            column-gap: 0;
            row-gap: rem-calc(28);
        }

        .title {
            font-family: $footer-font-family;
            font-weight: bold;
            font-size: rem-calc(25);
            margin: 0 0 rem-calc(25) 0;

            @include breakpoint(small down) {
                padding-right: 60px;
            }
        }

        .icons {

            line-height: 1;

            a {
                margin-right: rem-calc(10);
                text-decoration: none;

                &:hover {
                    opacity: .5;
                }
            }
            .icon {
                width: rem-calc(32);
                cursor: pointer;
            }
            .facebook {
                content: url('/static/assets/images/ico_facebook.png');
            }
            .youtube {
                content: url('/static/assets/images/ico_youtube.png');
            }
            .instagram {
                content: url('/static/assets/images/ico_instagram.png');
            }
        }
    }

    .bottom-content {

        @include flex;
        @include flex-direction(column);
        width: 100%;

        @include breakpoint(large) {
            @include flex-direction(row);
            @include flex-align(justify, middle);

        }

        .links-list {
            li {
                .general-link {
                    font-weight: normal;
                }
            }
        }

        .schweiz-naturlich {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            background-color: $schweiz-red;
            height: rem-calc(60);
            max-width: rem-calc(190);
            width: 100%;

            @include breakpoint(medium down) {
                margin: rem-calc(14) 0;
                order: 2;
            }

            @include breakpoint(large up) {
                font-size: rem-calc(13);
                order: 3;
            }

            .text {
                color: $white;
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                font-weight: 700;
                letter-spacing: .04em;
                white-space: nowrap;
                margin-bottom: 3px;
                font-size: rem-calc(13);
            }
        }

        ul {
            display: flex;
            flex-direction: column-reverse;
            margin: 0;
            padding: 0;
            height: 100%;
            list-style: none;
            order: 1;

            @include breakpoint(large) {
                flex-direction: row;
                @include flex-align(right, middle);
                order: 2;
            }
        }

        li {
            margin: rem-calc(14) 0;

            @include breakpoint(large) {
                margin: 0 rem-calc(40) 0 0;
            }
        }

        a {
            color: $footer-text-color;
            transition: color .2s;

            &:hover {
                color: rgba($footer-text-color, .7);
            }

            &::after {
                display: none;
            }
        }

        .copyright {
            order: 1;
            flex-grow: 1;

            @include breakpoint(medium down) {
                margin: rem-calc(14) 0;
                order: 3;
            }
        }
    }
}
