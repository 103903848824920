.comp-change-password {
    .page-header {
        text-align: center;
        max-width: rem-calc(716);
        margin-top: rem-calc(80);
        margin-left: auto;
        margin-right: auto;
        overflow:auto;
    }

    .page-body {
        margin-top: rem-calc(60);
    }

    .side-menu {
        padding-top: rem-calc(79);

    }
}
