$input-label-color: rgb(0, 0, 0) !default;
$input-bg-color: rgb(255, 255, 255) !default;
$input-border-color: rgb(196, 196, 196) !default;
$input-font: 'Asap', Arial, serif !default;
$input-color: rgb(41, 41, 41) !default;
$input-mutted-color: rgb(153, 153, 153) !default;
$input-error-color: rgb(255, 0, 0) !default;
$input-focus-color: rgb(0, 0, 0) !default;
$input-icon-primary-color: rgba(255,255,255,1) !default;
$input-icon-primary-bg-color: rgba(124,182,79,1) !default;
$input-icon-primary-bg-hover-color: rgba(76,134,32,1) !default;
$red-error: rgb(204, 75, 55) !default;

.form-field {
    display: block;
    margin-bottom: rem-calc(24);

    select {
        appearance: none;
        width: 100%;
        padding: rem-calc(15) rem-calc(20);
        font-family: $input-font;
        letter-spacing: 0.02em;
        word-spacing: 0.02em;
        font-size: rem-calc(18);
        line-height: rem-calc(28);
        font-weight: normal;
        color: $input-color;
        background-color: $input-bg-color;
        border: 1px solid $input-border-color;
        border-radius: 0;
        text-overflow: ellipsis;

        // sass-lint:disable no-vendor-prefixes
        &::-ms-expand {
            display: none;
        }

        &:-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 $black;
        }
        // sass-lint:enable no-vendor-prefixes
    }

    select:focus {
        outline-color: $input-focus-color;
    }

    &.disabled {
        opacity: .5;

        select {
            background-color: transparent;
            box-shadow: none;
            cursor: default;
        }
    }

    &.error {
        margin-bottom: rem-calc(6);

        select {
            border-color: $red-error;
        }

        .error-message {
            display: inline-block;
        }
    }

    .select-wrapper {
        display: block;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: rem-calc(20);
            right: rem-calc(23);
            width: rem-calc(11);
            height: rem-calc(11);
            border-bottom: 1px solid $input-color;
            border-left: 1px solid $input-color;
            transform: rotate(-45deg);

            pointer-events: none;
        }
    }
}