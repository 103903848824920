.comp-dashboard {
    @extend .home-page;

    section {
        padding: 0;
    }

    .grid-x {
        justify-content: center;
    }

    .lead-heading {
        margin: rem-calc(125) auto rem-calc(50);
        max-width: 90%;
        text-align: center;
        letter-spacing: .01em;

        @include breakpoint(medium) {
            max-width: 70%;
        }
    }

    .button-create-farm {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 200px;
    }

    .comp-project-overview-in-progress {

        .intro-text {
            padding: 0;

            button {
                display: inline-block;
            }
        }
    }
}
