@charset 'UTF-8';

// Asap

@font-face {
    font-family: 'Asap';
    src: url('/static/assets/fonts/asap/asap-regular.woff2') format('woff2'),
    url('/static/assets/fonts/asap/asap-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Asap';
    src: url('/static/assets/fonts/asap/asap-italic.woff2') format('woff2'),
    url('/static/assets/fonts/asap/asap-italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Asap';
    src: url('/static/assets/fonts/asap/asap-bold.woff2') format('woff2'),
    url('/static/assets/fonts/asap/asap-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

// Open Sans

@font-face {
    font-family: 'Open Sans';
    src: url('/static/assets/fonts/open-sans/OpenSans-Light.woff2') format('woff2'),
    url('/static/assets/fonts/open-sans/OpenSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/static/assets/fonts/open-sans/OpenSans-Regular.woff2') format('woff2'),
    url('/static/assets/fonts/open-sans/OpenSans-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/static/assets/fonts/open-sans/OpenSans-Semibold.woff2') format('woff2'),
    url('/static/assets/fonts/open-sans/OpenSans-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/static/assets/fonts/open-sans/OpenSans-ExtraBold.woff2') format('woff2'),
    url('/static/assets/fonts/open-sans/OpenSans-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

// Icomoon

@font-face {
    font-family: 'Icomoon';
    src: url('/static/assets/fonts/icomoon/icomoon-ultimate.woff2') format('woff2'),
    url('/static/assets/fonts/icomoon/icomoon-ultimate.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

// Foundation icons

@font-face {
    font-family: 'Foundation Icons';
    src: url('/static/assets/fonts/foundation-icons/foundation-icons.woff2') format('woff2'),
    url('/static/assets/fonts/foundation-icons/foundation-icons.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}