$comp-preview-indicator-background-color: #f7f7f7;

.comp-preview-indicator {
    height: 90px;
    width: 100%;
    background-color: $comp-preview-indicator-background-color;
    position: fixed;
    z-index: 1000;

    .full-width {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        max-width: initial;

        .columns {
            height: 90px;
            line-height: 90px;
        }

        .action-column {
            text-align: right;

            .button:nth-child(2) {
                margin-left: 34px;
            }
        }

        .title-column {
            h2 {
                font-size: rem-calc(24);
                height: 90px;
                line-height: 90px;
                margin: 0;
            }

            text-align: center;
        }
    }

    @include breakpoint(medium down) {
        height: 178px;

        .full-width {
            .columns {
                text-align: center;
            }

            .close-column {
                height: 60px;
                line-height: 60px;

                button {
                    border: none;
                    margin: 0;
                }
            }

            .action-column {
                height: 80px;
                line-height: 80px;
                text-align: center;

                .button:nth-child(2) {
                    margin-left: 13px;
                }

                button {
                    font-size: 14px;
                    padding: 13px 0;
                    width: 131px;
                    min-width: 131px;
                    max-width: 131px;
                    text-align: center;
                }
            }

            .title-column {
                height: 38px;
                line-height: 38px;

                h2 {
                    font-size: rem-calc(20);
                    height: 38px;
                    line-height: 38px;
                }
            }
        }
    }

    @media (max-width: 292px) {
        height: 260px;

        .full-width {
            .action-column {
                .button:nth-child(2) {
                    margin-left: 0px;
                }
            }
        }
    }
}