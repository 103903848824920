$file-upload-preview-background-color: rgba(0, 0, 0, .05) !default;
$file-upload-preview-border-color: rgba(0, 0, 0, 0.2) !default;
$input-error-color: red !default;
$red-error: rgb(204, 75, 55) !default;

@mixin preview-item {
    position: relative;
    width: rem-calc(275);
    height: rem-calc(320);
    background-color: $file-upload-preview-background-color;
}

.image-upload-wrapper,
.file-upload-wrapper {
    max-width: 555px;
    margin: auto auto 100px auto;

    .space-2 {
        margin: 0;
        position: absolute;
        width: 100%;
        text-align: center;
    }

    .error {
        color: $red-error;
        margin-bottom: rem-calc(30);
    }

    // Progress
    .progress {
        display: none;

        &.is-active {
            display: block;
        }

        .progress__value {
            display: block;
            font-weight: bold;
        }

        progress {
            width: 100%;
        }
    }

    & > .file-upload.no-init {
        position: relative;
        width: 553px;
        max-width: 554px;
        margin: auto auto 30px;

        @media (max-width: 600px) {
            width: 100%;
            max-width: inherit;
        }

        background-color: $white;
        border-radius: 13px;
        min-height: 470px;

        @media (max-width: 425px) {
            min-height: 90vw;
        }

        &::before {
            content: '';
            position: absolute;
            top: -2px;
            right: -2px;
            bottom: -2px;
            left: -2px;
            border: 15px dashed lightgray;
            border-radius: 15px;
            z-index: -1;
        }

        &.has-error {
            &::before {
                border-color: $red-error;
            }
        }

        // File-Select
        .file-select {
            form {
                margin: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;

                input {
                    opacity: 0;
                    visibility: hidden;
                    position: absolute;
                }

                .button {
                    z-index: 99;
                }
            }

            label {
                display: block;
                padding: rem-calc(10) rem-calc(16);
                width: rem-calc(200);
                text-align: center;
                cursor: pointer;
                border: 1px solid rgba(0, 0, 0, .1);
            }

            label + .error {
                margin-top: rem-calc(6);
            }
        }

        .progress {
            position: absolute;
            z-index: 100;
            width: 100%;
            padding: 0 20px;
            top: 40%;
            flex-wrap: wrap;
            height: 100%;
            background: rgba(255, 255, 255, 0.65);
            top: 0;
            align-items: center;
            align-content: center;
            border-radius: 20px;

            &.is-active {
                display: flex;
            }

        }

        .space-2 {
            bottom: -100px;
        }

        &.empty {
            .file-select {

                form {
                    background-image: url('/static/assets/images/image-upload-bg.svg');
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: top center;

                    width: calc(100% - 40px);
                    height: calc(100% - 40px);

                    margin: 20px;
                    visibility: visible;
                    opacity: 1;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;

                    @media (max-width: 425px) {
                        min-height: calc(90vw - 40px);
                    }

                    input {
                        opacity: 0;
                        visibility: hidden;
                        position: absolute;
                    }

                    .button {
                        z-index: 99;
                    }
                }
            }
        }

        &:not(.empty) .preview {
            padding: 20px 20px 10px 20px;
        }

    }

    .file-upload.init {
        form {
            opacity: 0;
            visibility: hidden;
            position: absolute;
        }

        .space-2 {
            position: static;
        }
    }

    .file-upload {
        .file-select {
            position: static;
        }
    }

    .title-image {
        .title-image-flag {
            position: absolute;
            top: rem-calc(6);
            left: rem-calc(6);

            padding: 2px 9px;

            size: rem-calc(10);
            color: $white;
            background-color: $sbv-green;
            border-radius: 5px;
        }
    }
}

.file-upload-wrapper {

    & > .file-upload.no-init {
        min-height: 240px;

        @media (max-width: 425px) {
            min-height: 50vw;
        }

        &.empty {
            .file-select {

                form {
                    background-image: url('/static/assets/images/file-upload-bg.svg');

                    @media (max-width: 425px) {
                        min-height: calc(50vw - 40px) !important;
                    }
                }
            }
        }

        &:not(.empty) {
            .preview {
                padding: 20px;
            }
        }
    }
}

// Preview
.preview {
    margin-right: -10px;

    .add-more {
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        position: absolute;
        left: 0;
        top: 0;
        font-size: 64px;
        font-weight: bold;
        border-radius: 0;
        padding: 0;
        margin: 0 10px 10px 0;

        &:focus {
            background: $sbv-green;
        }

        .icon-plus {
            position: relative;
            bottom: 6px;
        }
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;

        @media (max-width: 600px) {
            justify-content: space-between;
        }

        .item {
            float: none !important;
            margin: 0 !important;

            .link {
                font-weight: normal;
                color: #333;
                display: inline-block;
                padding-left: 25px;
                cursor: pointer;
                position: absolute;
                top: 2px;

                &:hover {
                    color: #7CB64F;

                    &::before {
                        color: #7CB64F;
                    }
                }

                &::before {
                    font-family: 'Icomoon';
                    position: absolute;
                    left: 0;
                    top: -2px;
                    font-size: 16px;
                }

                &::after {
                    display: none;
                }

                &.edit {
                    margin-left: 5px;

                    &::before {
                        content: "\e90e";
                    }
                }

                &.delete {
                    right: 0;

                    &::before {
                        content: "\ebfd";
                    }
                }

                &.main-image {
                    right: 1.5rem;
                }
            }

        }
    }

    &.images {

        .item {
            position: relative;
            width: 33.333%;
            padding: 0 10px 10px 0;
            padding-bottom: calc(33.333% + 50px);

            @media (max-width: 425px) {
                width: 50%;
                padding-bottom: calc(50% + 50px);
            }

            .preview-actions {
                position: absolute;
                width: 100%;
                height: 50px;
                bottom: 0;
                left: 0;

                .remove {
                    cursor: pointer;
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    width: 28px;
                    height: 28px;

                    border-radius: 50%;
                    background: $sbv-green;
                    opacity: .75;
                    transform: scale(1);
                    transition: all .35s ease-in-out;

                    &::before {
                        content: "";
                        position: absolute;
                        width: 50%;
                        height: 2px;
                        background: #FFF;
                        left: 7px;
                        top: 13px;
                        transform: rotate(45deg);
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        width: 2px;
                        height: 50%;
                        background: #FFF;
                        left: 13px;
                        top: 7px;
                        transform: rotate(45deg);
                    }

                    &:hover {
                        opacity: 1;
                        transform: scale(1.2);
                        transition: all .25s cubic-bezier(0, 1, .51, 1.2);
                    }
                }
            }

            .preview-item {
                width: 100%;
                height: calc(100% - 50px);
                position: absolute;
                padding: 0 10px 10px 0;
                background: none;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    font-family: 'object-fit: cover;';
                }

            }

            &.new {
                img {
                    opacity: .3;
                }
            }
        }

        .no-init & {
            .item {
                padding-bottom: 33.333%;

                @media (max-width: 425px) {
                    padding-bottom: 50%;
                }

                .preview-item {
                    height: 100%;
                }
            }

        }
    }

    &.files {
        margin-right: 0;
        margin-bottom: rem-calc(30);

        ul {
            flex-direction: column;

            .item {
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                padding: 0 15px;
                font-size: 18px;

                &:nth-child(2n-1) {
                    background: #F2F4F8;
                }

                &.new {
                    opacity: .5;
                }

                &:not(.new) {
                    &:last-child {
                        padding: 0;
                    }
                }

                .delete {
                    top: 20px;
                    right: 10px;
                }

                .preview-item {
                    width: 100%;
                    height: 100%;
                }

                .add-more {
                    width: 100%;
                    height: 100%;
                    font-size: 18px;
                    font-weight: bold;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0;

                    .icon-plus {
                        font-size: 26px;
                        margin-right: 5px;
                        bottom: 0;
                        height: 32px;
                    }
                }
            }
        }
    }

    .preview-actions {
        .title {
            color: #333;
            font-size: 14px;
            max-width: 60%;
            display: inline-block;
            overflow: hidden;
            height: 1.2rem;
            line-height: 1.2rem;
        }
    }

    .file-select label {
        @include preview-item;

        &:before {
            content: "";
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }
    }
}
