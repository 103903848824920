.top-message {
    background-color: $sbv-green;

    .tm-content {
        display: flex;
        align-items: center;
        min-height: rem-calc(133);
    }

    p {
        margin: 0;
        padding: rem-calc(18) rem-calc(30);
        max-width: rem-calc(730);
        color: $white;
        line-height: rem-calc(28);
        font-size: rem-calc(18);
        font-family: $heading-font-family;
        font-weight: 700;
        letter-spacing: .02em;
        word-spacing: .02em;

        @include breakpoint(medium) {
            padding: rem-calc(18) rem-calc(50);
        }
    }
}