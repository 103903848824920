$header-bg: rgb(247, 247, 247) !default;             // #f7f7f7
$border-color: rgb(126, 126, 126) !default;          // #7e7e7e
$link-color: rgb(49, 83, 6) !default;                // #315306

.single-project-header {
    margin-top: 0;
    padding: rem-calc(75) 0 rem-calc(5);
    font-family: $heading-font-family;
    letter-spacing: .02em;
    word-spacing: .02em;
    background-color: $header-bg;

    a {
        padding-left: rem-calc(33);
        color: $link-color;

        &::before {
            content: '\eec9';
            position: absolute;
            top: -2px;
            left: 4px;
            font-size: rem-calc(20);
            font-family: $icons-font-family;
            font-weight: 400;
        }

        &::after {
            display: none;
        }
    }

    .sp-img {
        display: block;
        margin: rem-calc(20) auto;
        width: auto;
        max-height: rem-calc(280);

        @include breakpoint(large) {
            margin: rem-calc(20) 0 rem-calc(38);
        }
    }

    .sp-more-info {
        text-align: center;
    }

    .sp-desc-box {
        display: flex;
        flex-direction: column;

        &.desc-open {

            .sp-desc {
                max-height: rem-calc(100000);
                transition: max-height .8s cubic-bezier(1, 0, 1, 0);
            }

            .sp-read-more {

                &::before {
                    top: 2px;
                    transform: rotate(180deg);
                }
            }
        }
    }

    .sp-desc {
        padding-left: rem-calc(25);
        transition: max-height .4s cubic-bezier(0, 1, 0, 1);

        @include breakpoint(large up) {
            max-height: rem-calc(322);
            overflow: hidden;
        }

        .sp-heading {
            max-width: rem-calc(500);
            line-height: rem-calc(40);
            font-size: rem-calc(34);
        }

        .sp-list {
            margin: 0;
            padding: 0;
            max-width: rem-calc(500);
            list-style: none;

            li {
                position: relative;
                margin-bottom: rem-calc(16);
                padding-left: rem-calc(20);
                line-height: rem-calc(28);
                font-size: rem-calc(18);

                &::before {
                    content: '\ed6f';
                    position: absolute;
                    top: -1px;
                    left: 0;
                    color: $link-color;
                    font-size: rem-calc(15);
                    font-family: $icons-font-family;
                }
            }
        }

        p {
            margin-top: -5px;
        }
    }

    .sp-read-more {
        display: inline-block;
        margin: rem-calc(8) auto;
        padding: 0 rem-calc(12) 0 0;
        font-size: rem-calc(18);

        &::before {
            content: '\edc0';
            top: 0;
            left: 100%;
            font-size: rem-calc(16);
            transform: rotate(0deg);
            transition: transform .3s;
        }

        @include breakpoint(large) {
            margin-left: rem-calc(220);
        }
    }
}