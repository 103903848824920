$input-mutted-color: rgb(153, 153, 153) !default;
$sub-menu-small-navigation-height: rem-calc(68) !default;
$sub-menu-navigation-height: rem-calc(90) !default;

.comp-vomhof {
    margin-top: $sub-menu-small-navigation-height;

    @include breakpoint(large) {
        margin-top: $sub-menu-navigation-height;
    }

    .page-body {
        margin-top: rem-calc(60);
        margin-bottom: rem-calc(20);
    }


    .select-product,
    .select-vacation {
        max-width: 555px;
    }

    .button-group {
        @include breakpoint(small down) {
            flex-wrap: wrap;
        }

        .button {
            margin-bottom: 0;
        }

        .offer-remove-link {
            margin-top: auto;

            @include breakpoint(small down) {
                margin-bottom: 1.5rem;
            }

            a {
                color: $input-mutted-color;

                &:after {
                    background-color: $input-mutted-color;
                }
            }
        }
    }

    .single-project-header {
        a {
            color: $sbv-vomhof;
        }

        .sp-desc {
            .sp-list {

                li {

                    &::before {
                        color: $sbv-vomhof;

                    }
                }
            }
        }
    }

    .form-field {
        .optional-form-link {
            h3 {
                &::before {
                    background-color: $sbv-vomhof;
                }
                @include breakpoint(large) {
                    max-width: 85%;
                }
            }
        }
    }

    .comp-project-status-widget {
        .central-content {
            background-color: $sbv-vomhof;
        }

    }

    .section-select-product {
        margin-top: rem-calc(100);
    }
}