$input-label-color: rgb(0, 0, 0) !default;
$input-bg-color: rgb(255, 255, 255) !default;
$input-border-color: rgb(196, 196, 196) !default;
$input-font: 'Asap', Arial, serif !default;
$input-color: rgb(41, 41, 41) !default;
$input-mutted-color: rgb(153, 153, 153) !default;
$input-error-color: rgb(255, 0, 0) !default;
$input-focus-color: rgb(0, 0, 0) !default;
$input-icon-primary-color: rgba(255,255,255,1) !default;
$input-icon-primary-bg-color: rgba(124,182,79,1) !default;
$input-icon-primary-bg-hover-color: rgba(76,134,32,1) !default;
$red-error: rgb(204, 75, 55) !default;

.form-field {
    display: block;
    margin-bottom: rem-calc(48);
    width: 100%;

    textarea {
        padding: rem-calc(15) rem-calc(20);
        font-family: $input-font;
        letter-spacing: 0.02em; 
        word-spacing: 0.02em; 
        font-size: rem-calc(18);
        line-height: rem-calc(28);
        width: 100%;
        min-height: 155px;
        font-weight: normal;
        color: $input-color;
        background-color: $input-bg-color;
        border: 1px solid $input-border-color;
        border-radius: 0;
        box-shadow: none;
        resize: vertical;
    }

    &.disabled {
        opacity: .5;
    }

    &.error {
        margin-bottom: rem-calc(6);

        textarea {
            border: 1px solid $red-error;
        }
    }
}