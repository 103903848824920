$heading-font-family: 'Asap', 'Helvetica Neue', Helvetica, Arial, sans-serif !default;
$nav-sm-width: 78.26% !default;

$sub-menu-color: rgb(51, 51, 51) !default; // #333333
$sub-menu-hover-color: rgb(174, 174, 174) !default; // #aeaeae

$sub-menu-small-navigation-height: rem-calc(68) !default;
$sub-menu-navigation-height: rem-calc(90) !default;
$sub-menu-navigation-active-color: #ccc !default;
$disabled-background: rgba(174, 174, 174, 1) !default;
$sub-menu-background-color: rgb(174, 174, 174) !default;
$sub-menu-small-height-calc: rem-calc(68) !default;
$sub-menu-caret-color: #000 !default;
$nav-border-color: rgba(124, 182, 79, .2784) !default; // #86a65f - 27.84%

$main-navigation-height: rem-calc(108);
$main-navigation-height-full: rem-calc(165) !default;

body {
    .sub-menu-container {
        display: block;
        position: fixed;
        top: $main-navigation-height;
        left: 0;
        width: 100%;
        background-color: $white;
        z-index: 1000;

        @include breakpoint(large) {

            top: $main-navigation-height-full;

            .sub-menu-label {
                display: none;
            }

            .sub-menu {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                height: $sub-menu-navigation-height;
                list-style: none;

                li {
                    margin: 0 20px;
                    cursor: pointer;
                    text-align: center;

                    &.active {
                        border-bottom: 4px solid $sub-menu-color;

                        &:hover {
                            border-bottom: 4px solid $sub-menu-hover-color;
                        }

                        cursor: default;
                    }

                    &:hover {
                        a {
                            color: $sub-menu-hover-color;
                        }

                        /* border fade in */
                        border-bottom: 4px solid $sub-menu-hover-color;
                        transition: 0.1s;
                        transition-timing-function: ease-in;
                    }

                    a {
                        color: $sub-menu-color;
                        line-height: rem-calc(34);
                        font-size: rem-calc(18);
                        font-family: $heading-font-family;
                        text-decoration: none;
                        transition: color .2s;

                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }

        @include breakpoint(medium down) {
            height: $sub-menu-small-height-calc;
            border-bottom: 1px solid $nav-border-color;
            transition: left .2s;

            .sub-menu {
                display: none;
            }

            &.open {
                .sub-menu-label {

                    .active-element {
                        span {

                            &::before {
                                content: '';
                                position: absolute;
                                top: 0;
                                right: 0;
                                margin-right: rem-calc(-70);
                                width: rem-calc(11);
                                height: rem-calc(11);
                                border-bottom: 2px solid $sub-menu-caret-color;
                                border-left: 2px solid $sub-menu-caret-color;
                                transform: rotate(135deg);
                                pointer-events: none;
                                z-index: 100000;
                                margin-top: rem-calc(5);
                            }
                        }
                    }
                }

                .sub-menu {
                    list-style: none;
                    display: block;
                    position: fixed;
                    top: $main-navigation-height;
                    width: 100%;
                    height: auto;
                    max-height: 80vh;
                    overflow-y: scroll;
                    margin: 0;
                    z-index: 1000;

                    li {
                        cursor: pointer;
                        background-color: #fff;
                        opacity: 1;
                        line-height: $sub-menu-small-navigation-height;
                        font-size: rem-calc(14);
                        font-family: $heading-font-family;
                        font-weight: bold;

                        &:hover {
                            color: $sub-menu-navigation-active-color;
                        }

                        a {
                            width: calc(100%);
                            text-align: center;
                            display: inline-block;
                            color: inherit;

                            &::after {
                                display: none;
                            }
                        }
                    }
                }

                .menu-overlay {
                    position: fixed;
                    top: $main-navigation-height;
                    width: 100vw;
                    height: 100vh;
                    background: $disabled-background;
                    opacity: .7;
                }
            }

            .sub-menu-label {
                width: 100%;
                height: 100%;

                .active-element {
                    width: 100%;
                    position: absolute;
                    height: 100%;
                    top: 0;
                    left: 0;
                    max-height: 100%;
                    line-height: $sub-menu-small-navigation-height;
                    font-size: rem-calc(14);
                    font-family: $heading-font-family;
                    font-weight: bold;
                    text-align: center;
                    cursor: pointer;

                    span {
                        position: relative;

                        &::after {
                            content: '';
                            position: absolute;
                            top: 0;
                            right: 0;
                            margin-right: rem-calc(-70);
                            width: rem-calc(11);
                            height: rem-calc(11);
                            border-bottom: 2px solid $sub-menu-caret-color;
                            border-left: 2px solid $sub-menu-caret-color;
                            transform: rotate(-45deg);
                            pointer-events: none;
                        }
                    }
                }
            }
        }
    }

    &.nav-open {
        .sub-menu-container {
            @include breakpoint(medium down) {
                left: - $nav-sm-width;
            }
        }
    }
}
