// Buttons

.button {
    @include button-style($sbv-green, $sbv-dark-green, $white);
    margin: rem-calc(20) 0;
    padding: rem-calc(15) rem-calc(30);
    color: $white;
    font-size: rem-calc(18);
    font-family: $heading-font-family;
    font-weight: 700;
    letter-spacing: .02rem;
    transition: background-color .2s;
    border-radius: 6px;
    cursor: pointer;

    &[disabled] {
        @include button-style($disabled-background, $disabled-background, $disabled-color)
    }

    &.hollow {
        @include button-hollow-style($sbv-green, 0px, 1px);

        &.secondary:hover {
            @include button-hollow-style($sbv-yellow-dark, 0%, 1px);
        }

        &:hover {
            @include button-hollow-style($sbv-dark-green, 0%, 1px);
        }
    }

    .button-group & {
        font-size: rem-calc(18);

        @include breakpoint(small down) {
            width: 100%;
        }
    }

    &.to-right {
        margin-left: auto;
    }

    &.sbv-vomhof {
        background-color: $sbv-vomhof;

        &:hover {
            background-color: $sbv-dark-vomhof;
        }
    }

    &.sbv-brunch {
        background-color: $sbv-brunch;

        &:hover {
            background-color: $sbv-dark-brunch;
        }
    }

    &.sbv-schub {
        background-color: $sbv-schub;

        &:hover {
            background-color: $sbv-dark-schub;
        }
    }

    &.sbv-stallvisite {
        background-color: $sbv-stallvisite;

        &:hover {
            background-color: $sbv-dark-stallvisite;
        }
    }
}

// Links

a {
    position: relative;
    color: $sbv-green;
    font-weight: 600;
    text-decoration: none;
    transition: color .2s;
    outline: 0;

    &:hover,
    &:focus {
        color: $sbv-dark-green;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: $sbv-green;
    }

    &.silver {
        color: $silver;

        &::after { background: $silver; }
    }

    &.black {
        color: $black;

        &::after { background: $black; }
    }

    &:hover { color: $sbv-dark-green; }
    &::after { background: $sbv-green; }
}

.page-header {
    text-align: center;
    max-width: rem-calc(716);
    margin-top: rem-calc(80);
    margin-left: auto;
    margin-right: auto;
    overflow: auto;
}

// Modal 

.modal {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .modal__background {
        background: $modal-bg;
    }

    .modal__content {
        padding: 0;
        top: 0;
        overflow: hidden;

        @include breakpoint(large) {
            max-width: rem-calc(1200);
        }

        @media (max-height: 900px) {
            max-height: 96%;
        }

        @media (max-height: 900px) {
            overflow-y: scroll;
        }

        .button {
            min-width: rem-calc(200);
        }

        h2 {
            max-width: 80%;
        }
    }

    &:not([id='login-modal']) {

        .modal__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: rem-calc(60) rem-calc(15) rem-calc(40);

            @include breakpoint(large) {
                padding: rem-calc(110) rem-calc(15) rem-calc(85);
                max-width: rem-calc(600);
            }

            @include breakpoint(small down) {
                .button-group {
                    display: block;

                    .to-right {
                        margin-left: 0;
                    }
                }
            }

            .grid-container {
                @include breakpoint(large) {
                    max-width: rem-calc(600);
                }
            }

            h2 {
                max-width: 100%;
                margin: auto auto;
            }

            p, ul {
                margin-top: rem-calc(30);
                margin-bottom: rem-calc(30);
            }
        }
    }
}

// Side Menu - sticky

.page-body {
    align-items: flex-start;
}

.side-menu {
    position: sticky;
    top: 110px;
    padding-top: rem-calc(79);
}

.comp-publish-banner {
    &.visible + .page-body {
        .side-menu {
            top: 180px;
        }
    }
}

.detail-preview {
    margin-top: rem-calc(24);
    margin-bottom: rem-calc(16);
    padding-left: rem-calc(57);
    padding-right: rem-calc(57);
}

