$side-menu-active-color: rgb(174, 174, 174) !default; // #aeaeae

.side-menu-container {

    .side-menu-navigation {

        .side-menu-item {
            list-style: none;
            margin-top: rem-calc(24);

            a {
                &:after {
                    content: none;
                }

                &:hover {
                    color:  $silver;
                }
            }
        }
    }
}

