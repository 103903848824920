.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.object-fit-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.right {
    float: right;
}

.left {
    float: left;
}

@include breakpoint(large) {

    .right-lg {
        float: right;
    }

    .left-lg {
        float: left;
    }
}