.comp-publish-banner {
    height: rem-calc(90);

    .button-group {
        margin-bottom: 0;
        margin-right: 2rem;
        flex-grow: unset;

        .button {
            margin-bottom: 0;
            white-space: nowrap;
        }

        .button-hollow {
            border: 1px solid white;
        }
    }

    .top-message {
        background-color: $sbv-green !important;
        position: fixed !important;
        width: 100%;
        z-index: 800;
        height: auto;

        .bg-white {
            background-color: $white;
        }

        &.secondary {
            background-color: $sbv-yellow !important;
        }

        p {
            padding-left: 3%;
        }
    }

    @include breakpoint(medium down) {
        .button-group {

            .button {
                font-size: rem-calc(14);
            }
        }
    }

    @include breakpoint(small down) {
        .publish-text {
            font-size: 0.75rem;
            line-height: 1.5 !important;
            margin-top: 0;
            padding: rem-calc(10);
        }

        .comp-publish-banner .top-message {
            height: auto !important;
        }

        .button-group {
            margin-right: rem-calc(10);
            flex-wrap: wrap;

            .button {
                margin: 0;
                font-size: rem-calc(12);
                margin-bottom: rem-calc(12);

                &:first-child {
                    margin-top: rem-calc(12);
                }
            }
        }
    }
}

.top-message .tm-content {
    min-height: 0 !important;
    justify-content: center;
    &.open, &.online {
        display: block;
        text-align: center;
    }
}

.top-message p {
    max-width: none !important;
}

@include breakpoint(small down) {
    .top-message {
        height: auto !important;
    }
}