h1 {
    margin: rem-calc(15) 0;
    line-height: rem-calc(34);
    font-size: rem-calc(26);
    font-family: $heading-font-family;
    letter-spacing: .02em;
    word-spacing: .02em;
    font-weight: 700;

    @include breakpoint(medium) {
        margin: rem-calc(15) 0 rem-calc(30);
        line-height: rem-calc(42);
        font-size: rem-calc(35);
    }

    @include breakpoint(large) {
        line-height: rem-calc(52);
        font-size: rem-calc(45);
    }
}

h2 {
    margin: rem-calc(15) 0 rem-calc(25);
    line-height: rem-calc(24);
    font-size: rem-calc(20);
    font-family: $heading-font-family;
    letter-spacing: .02em;
    word-spacing: .02em;
    font-weight: 700;

    @include breakpoint(medium) {
        line-height: rem-calc(32);
        font-size: rem-calc(25);
    }

    @include breakpoint(large) {
        line-height: rem-calc(40);
        font-size: rem-calc(34);
    }
}

h3,
.h3 {
    margin-top: rem-calc(24);
    font-size: rem-calc(18);
    font-family: $heading-font-family;
    font-weight: 700;

    @include breakpoint(medium) {
        font-size: rem-calc(19);
    }
}

p {
    line-height: rem-calc(28);
    font-size: rem-calc(18);
}

small {
    font-size: rem-calc(15);
}