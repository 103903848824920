@import "util/util";

$heading-font-family: 'Asap', 'Helvetica Neue', Helvetica, Arial, sans-serif !default;

$main-navigation-top-header-height: rem-calc(56) !default;
$main-navigation-top-header-link-hover-color: rgb(124, 182, 79) !default;
$nav-border-color: rgba(124, 182, 79, .2784) !default;
$top-header-color: rgb(51, 51, 51) !default; // #333333

$link-padding-top: (($main-navigation-top-header-height) / 2) - rem-calc(16 / 2);

$closebutton-offset-vertical: rem-calc(($main-navigation-top-header-height) / 4);

.comp-top-header {
    border-bottom: 1px solid $nav-border-color;

    .grid-container {
        display: none;
        height: $main-navigation-top-header-height;

        @include breakpoint('large') {
            @include flex;
        }

        ul {

            flex: 1;

            a {
                padding-top: $link-padding-top;
                font-family: $heading-font-family;
                transition: color 0s;
                color: $top-header-color;

                &::after {
                    display: none;
                }

                &:hover {
                    color: $main-navigation-top-header-link-hover-color;
                    border-bottom: 3px solid $main-navigation-top-header-link-hover-color;
                }
            }

            .search-button {
                width: $main-navigation-top-header-height;
                height: $main-navigation-top-header-height;
                background-image: url('/static/assets/images/search.svg');
                background-position: rem-calc(18);
                background-repeat: no-repeat;
                background-size: rem-calc(22);
                cursor: pointer;
                margin-right: rem-calc(-15);
            }
        }

        .search-input-wrapper {
            @include flex;
            flex: 1;

            form {
                @include flex;
                flex: 1;
            }

            .input-group {
                @include flex;
                flex: 1;
                position: relative;

                .input-group-field {
                    flex: 1;
                    background-color: #f3f3f3;
                    border-style: none;
                    text-indent: 15px;
                }

                .close-button {
                    top: $closebutton-offset-vertical;
                }
            }
        }
    }
}
