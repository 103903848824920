@import "util/util";

$darkorange: #FF8C00;
$orange: #FFA500;
$black: rgb(0, 0, 0) !default;
$white: #ffffff !default;

.comp-dashboard .custom-row section {
    padding: 0 20px;
    margin: 150px 0 0 0;
}

.comp-dashboard .black {
    background-color: $black;
    color: $white;
}

.comp-dashboard .custom-row .comp-project-overview-initial,
.comp-dashboard .custom-row .comp-project-overview-published {
    padding: 20px;
}

.comp-project-overview {
    padding-left: rem-calc(5.25) !important;
    padding-right: rem-calc(5.25) !important;
    margin-left: rem-calc(5.25) !important;
    margin-right: rem-calc(5.25) !important;

    .image {
        object-fit: cover;
        height: 222px;
    }

    h3 {
        margin-bottom: 20px;
    }

    .button {
        height: 70px;
        font-size: 24px;
        margin-bottom: 1.25rem;
    }
}

.comp-project-overview-initial {

    &.col-reverse {
        .grid-x {
            @include breakpoint(large) {
                @include flex-direction(row-reverse);
            }
        }
    }

    &.section-gray {
        background-color: $white;

        .intro-text {
            padding: 0;

            @include breakpoint(medium) {
                padding: 0 0 rem-calc(40);
            }

            @include breakpoint(large) {
                padding: rem-calc(140) 0;
            }

        }
    }

    .intro-text-bullet-list {
        margin: rem-calc(30) 0;
        padding: 0;
        line-height: rem-calc(28);
        font-size: rem-calc(18);
        list-style: none;

        @include breakpoint(medium) {
            margin: rem-calc(10) 0 rem-calc(15);
            font-size: rem-calc(15);
        }

        li {
            position: relative;
            margin-bottom: rem-calc(15);
            padding-left: rem-calc(25);

            &::before {
                content: '\ed6f';
                position: absolute;
                top: 0;
                left: 0;
                font-family: 'Icomoon';
            }

            @include breakpoint(medium) {
                margin-bottom: 0;
                padding-left: rem-calc(20);
            }
        }
    }

    .link-proj {
        margin-bottom: rem-calc(20);
        font: normal normal bold 14px Asap;
        &:hover {
            color: $sbv-dark-green;
        }
        &::after {
            display: none;
        }
        &.arrow-left:before {
            content: url("/static/assets/images/arrow-left.svg");
            width: 30px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
        }
    }

    .f-24 {
        font-size: rem-calc(24px);
    }

    .title.last {
        margin-bottom: 60px;
    }
}

.comp-dashboard {

    section {

        &.comp-project-overview-initial,
        &.comp-project-overview-published,
        &.comp-project-overview-in-progress {
            button {
                margin-top: auto;
            }
        }

        &.comp-project-overview-published,
        &.comp-project-overview-in-progress {
            .button-group {
                &.align-bottom {
                    align-content: flex-end;
                }
            }
        }
    }

    h3 {
        &.title {
            font-size: rem-calc(18px);
            color: $black;
            &.last {
                margin-top: 0;
                margin-bottom: 30px;
                font-size: rem-calc(25px);
            }
        }
    }
}

.comp-project-overview-published {

    .upper-content {
        height: rem-calc(522);
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        border-bottom: 0;

        .button {
            position: absolute;
            bottom: rem-calc(10);
            right: 50%;
            transform: translateX(50%);

            &.prev {
                width: rem-calc(460);
                height: rem-calc(70);

                @include breakpoint(small down) {
                    width: 75%;
                }
            }
        }
    }

    .bottom-content {
        height: rem-calc(120);
        color: $white;
        align-items: center;
        padding: 0 35px;

        @include breakpoint(medium down) {
            padding: 0;
            height: auto;
        }

        .left {
            text-align: left;

            h3 {
                font-size: rem-calc(24);
                margin: 0;

                @include breakpoint(medium down) {
                    text-align: center;
                    float: none;
                    padding: .5rem 0 1rem 0;
                }
            }
        }
    }

    .right {
        text-align: right;

        @include breakpoint(medium up) {
            padding-right: 15px;
        }
        @include breakpoint(medium down) {
            text-align: center;
        }

        .button.hollow {

            @include breakpoint(medium down) {
                margin-top: 5px;
            }
        }
    }

    &.brunch {

        @include breakpoint(large up) {
            .checkbox.fully-book-switch {
                padding-bottom: rem-calc(20);
            }
        }

        .fully-book-switch {

            @include breakpoint(medium down) {
                float: none;
                padding: 0 0 rem-calc(8) 0;
                text-align: center;
            }
        }
    }

    .title.last {
        margin-bottom: rem-calc(60);
        line-height: rem-calc(33);
    }
}

// Not published, in progress...
.comp-project-overview-in-progress {

    .grid-container {
        width: 100%;
    }

    .intro-text {
        font-size: rem-calc(18);
        margin: auto;

        h2 {
            font-size: 2.8125rem;
            line-height: 3.25rem;
            text-align: center;
            margin-bottom: rem-calc(60);
        }

        .progress-bar {
            height: 5px;
            background: #818181;
            margin-bottom: 3rem;

            &.cta-active {
                margin-bottom: 0.25rem;
            }

            span {
                height: 5px;
                display: block;
                background: $sbv-green;
            }
        }

        .cta {
            @include breakpoint(medium down) {
                .button {
                    width: 100%;
                }
            }
        }

        ul {
            margin: rem-calc(10) 0 rem-calc(15);
            padding: 0;
            list-style: none;

            @include breakpoint(medium) {
                margin: rem-calc(30) 0;
            }

            li {
                position: relative;
                padding-left: rem-calc(20);
                font-size: rem-calc(15);

                &::before {
                    content: '\ed6f';
                    position: absolute;
                    top: 0;
                    left: 0;
                    font-family: 'Icomoon';
                }

                @include breakpoint(medium) {
                    margin-bottom: rem-calc(15) !important;
                    padding-left: rem-calc(25) !important;
                    line-height: rem-calc(28);
                    font-size: rem-calc(16);
                }
            }
        }

        .button-group {
            display: flex;
            justify-content: center;

            .button {
                font-size: rem-calc(18);
                width: 50%;
                min-height: rem-calc(70);
            }
        }

        &.vomhof {
            li {
                &::before {
                    color: $sbv-vomhof;
                }
            }
        }

        &.brunch {
            li {
                &::before {
                    color: $sbv-brunch;
                }
            }
        }
    }

    &.secondary {
        .progress-bar {
            span {
                background: $sbv-yellow;
            }
        }
        .title.last {
            margin-bottom: rem-calc(30);
        }
    }

    &.primary .title.last {
        margin-bottom: rem-calc(30);
    }
}
