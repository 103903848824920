$main-font: 'Asap', Arial, serif !default;

.page-static {
    margin: rem-calc(48) auto;
    padding: 0 rem-calc(26);

    @include breakpoint(large) {
        margin: rem-calc(80) auto rem-calc(48);
        padding: 0 rem-calc(48);
    }

    p {
        font-weight: 400;
    }

    .static-list {
        margin: 0 0 rem-calc(32);
        list-style-position: inside;

        li {
            margin: rem-calc(32) 0 rem-calc(8);
            line-height: rem-calc(26);
            font-size: rem-calc(22);
            font-family: $main-font;
            font-weight: 700;
            letter-spacing: .02em;
            word-spacing: .02em;

            @include breakpoint(large) {
                line-height: rem-calc(40);
                font-size: rem-calc(34);
            }
        }

        h2 {
            display: inline;

            + p {
                margin-top: rem-calc(24);
            }
        }

        p {
            font-weight: 400;
        }

        a {
            display: inline-block;
        }

        // Used on Datenschutz page
        .ul-in-static {

            li {
                margin: 0;
                font-size: rem-calc(19);
                font-family: $main-font;

                @include breakpoint(medium) {
                    margin-left: rem-calc(16);
                }

                @include breakpoint(large) {
                    margin-left: rem-calc(32);

                    p {
                        margin: 0;
                    }
                }
            }

            @include breakpoint(large) {
                margin-bottom: rem-calc(16);
            }
        }
    }
}