// From https://projects.lukehaas.me/css-loaders/

.loader-container {
    width: 100%;
    height: 100%;

    .loader {
        margin: 0 auto;
    }

    &.loader-container--small {
        height: 25px;
    }
}

.loader {
    position: relative;
    display: block;
    font-size: 20px;
    text-indent: -9999em;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background-image: linear-gradient(to right, $body-font-color 10%, rgba(15, 17, 21, 0) 42%);
    animation: load3 1.4s infinite linear;
    transform: translateZ(0);
}

.loader::before {
    width: 50%;
    height: 50%;
    background: $body-font-color;
    border-radius: 100% 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}

.loader::after {
    background-color: $white;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.loader--small {
    font-size: 16px;
}

.loader--medium {
    font-size: 30px;
}

.loader--large {
    font-size: 40px;
}

@keyframes load3 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}