$input-label-color: rgb(0, 0, 0) !default;
$input-bg-color: rgb(255, 255, 255) !default;
$input-border-color: rgb(196, 196, 196) !default;
$input-font: 'Asap', Arial, serif !default;
$input-color: rgb(41, 41, 41) !default;
$input-mutted-color: rgb(153, 153, 153) !default;
$input-error-color: rgb(255, 0, 0) !default;
$input-focus-color: rgb(0, 0, 0) !default;
$input-icon-primary-color: rgba(255,255,255,1) !default;
$input-icon-primary-bg-color: rgba(124,182,79,1) !default;
$input-icon-primary-bg-hover-color: rgba(76,134,32,1) !default;
$red-error: rgb(204, 75, 55) !default;

.select-multiple {
    width: 100%;

    .multi-select-text {
        border-top: 1px solid $input-border-color;
        margin-bottom: rem-calc(24);
        list-style-type: none;
        margin-left: 0;

        .option {
            height: 59px;
            line-height: 59px;
            border: 1px solid $input-border-color;
            border-top: 0;
            padding: 0 19px;
            cursor: default;
            position: relative;
            cursor: pointer;

            &.selected {
                color: $input-icon-primary-bg-color;

                .icon {
                    display: inline-block;
                }
            }

            .icon {
                display: none;
                width: 28px;
                height: 28px;
                border-radius: 50%;
                background: $input-icon-primary-bg-color;
                position: absolute;
                right:16px;
                top: 16px;
                transform: rotate(-45deg);

                &::before {
                    content: "";
                    background: $input-bg-color;
                    width: 3px;
                    height: 8px;
                    position: absolute;
                    top: 9px;
                    left: 9px;
                }

                &::after {
                    content: "";
                    background: $input-bg-color;
                    width: 10px;
                    height: 3px;
                    position: absolute;
                    top: 14px;
                    left: 12px;
                }
            }
        }

        .icon {
            width: 28px;
            height: 28px;
            background: $input-icon-primary-bg-color;
            border-radius: 50%;

            &.icon-help {
                position: absolute;
                right:16px;
                top: 16px;
            }
        }

        .disabled & {
            opacity: .5;
        }
    }

    &.error {
        margin-bottom: rem-calc(6);

        select {
            border-color: $red-error;
        }

        .multi-select-text {
            border: 1px solid $red-error;
        }

        .error-message {
            display: inline-block;
        }
    }
}