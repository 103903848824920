$black: rgb(0,0,0) !default;                 // #000000
$white: rgb(255,255,255) !default;           // #ffffff
$silver: rgb(196,196,196) !default;          // #c4c4c4
$sbv-green: rgb(124,182,79) !default;        // #7cb64f
$btn-disabled: rgb(174,174,174) !default;    // #aeaeae

.registration-form {
    margin: rem-calc(60) 0 rem-calc(120);

    a {
        text-decoration: none;

        &:after {
            display: none;
        }
    }

    .input-group {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        &.street {

            .form-field {
                &:first-child { width: 69%; }
                &:last-child { width: 26%; }
            }
        }

        &.place {

            .form-field {
                &:first-child { width: 26%; }
                &:last-child { width: 69%; }
            }
        }
    }

    section.accordion {
        &.sec-optional {
            &.sec-on {
                &+ section.accordion {
                    &.agb {
                        margin-top: 0;
                    }
                }
            }

            &+ section.accordion {
                &.agb {
                    margin-top: rem-calc(142);
                }
            }
        }
    }
}

section.accordion {
    margin-top: rem-calc(24);
}