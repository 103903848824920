$black: rgb(41,41,41) !default;  //#292929
$stallvisite-project-published-background: #F7F7F7 !default;

.comp-project-overview-initial {

    &.stallvisite {
        .intro-text-bullet-list {
            li {
                &::before {
                    color: $black;
                }
            }

        }

        .button {
            @include button-style($black, $black, $white);

            &[disabled],
            &[disabled]:focus,
            &[disabled]:hover {
                @include button-style($disabled-background, $disabled-background, $disabled-color);
            }
        }
    }
}

.comp-project-overview-published {
    &.stallvisite {
        .right{
            .button {
                &.hollow {
                    @include button-hollow-style($sbv-green, 0px, 1px);

                    &:hover {
                        @include button-hollow-style($sbv-dark-green, 0%, 1px);
                    }
                }
            }
        }

        .bottom-content {
            color: $black;
            background: $stallvisite-project-published-background;
        }
    }
}

.comp-dashboard {
    .col-reverse, .section-gray {
        .intro-text {

            &.stallvisite {
                li {
                    &::before {
                        color: $black;
                    }
                }
            }
        }
    }
}
