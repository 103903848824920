$main-navigation-height-full: rem-calc(165) !default;
$main-navigation-height: rem-calc(108) !default;

* {
    outline: 0;
}

html,
body {
    color: $text-color;
    line-height: rem-calc(28);
    font-family: $body-font-family;
}

body {
    padding-top: $main-navigation-height;
    min-height: 100vh;

    @include breakpoint(large) {
        padding-top: $main-navigation-height-full;
        position: relative;
        padding-bottom: rem-calc(464);
    }
}